<template>
  <b-card
      class="feetransportsetting-edit-wrapper"
  >
    <!-- form -->
    <b-form id="feetransportsettingForm" class="edit-form mt-2">
      <!-- 基本信息 -->
      <b-card header="基本信息">
        <b-row>
          <!--调出仓库-->
          <b-col md="4" v-if="showFrom">
            <modal-select
                label="调出仓库 *"
                type="input"
                v-on:change="fromChildren($event,['warehouse_name_from','warehouse_id_from'])"
                :params="['warehouse_name_from','warehouse_id_from']"
                modalName="仓库"
                placeholder="点击选择仓库"
                v-model="feetransportsetting.warehouse_name_from"
            >
            </modal-select>
          </b-col>
          <!--调入仓库-->
          <b-col md="4" v-if="showTo">
            <modal-select
                label="调入仓库 *"
                type="input"
                v-on:change="fromChildren($event,['warehouse_name_to','warehouse_id_to'])"
                :params="['warehouse_name_to','warehouse_id_to']"
                modalName="仓库"
                placeholder="点击选择仓库"
                v-model="feetransportsetting.warehouse_name_to"
            >
            </modal-select>
          </b-col>
          <!--承运商-->
          <b-col md="4">
            <modal-select
                label="承运商 *"
                type="input"
                v-on:change="fromChildren($event,['company_name','company_id'])"
                :params="['company_name','company_id']"
                modalName="承运商"
                placeholder="点击选择承运商"
                v-model="feetransportsetting.company_name"
            >
            </modal-select>
          </b-col>
          <!--分类-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="产品分类"
                label-for="category"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="chooseId"
                  size="sm"
                  readonly
                  placeholder="请选择分类"
                  v-b-modal.modal-select-category
                  v-model="category_name_value"
              />
            </b-form-group>
          </b-col>
          <!--默认计价-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="默认计价"
                label-for="default_rule"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="default_rule"
                  :options="getCodeOptions('car_detail').slice(11,14)"
                  v-model="feetransportsetting.default_rule"
                  :reduce="option => option.value"
                  class="select-size-sm"
              />
            </b-form-group>
          </b-col>
          <!--不限调出仓库-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="不限调出仓库"
                label-for="is_limit_from"
                label-size="sm"
                class="mb-1"
            >
              <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  id="is_limit_from"
                  v-model="notLimitFrom"
                  @change="limitFrom"
              />

            </b-form-group>
          </b-col>
          <!--不限调入仓库-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="不限调入仓库"
                label-for="is_limit_to"
                label-size="sm"
                class="mb-1"
            >
              <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  id="is_limit_to"
                  v-model="notLimitTo"
                  @change="limitTo"
              />

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="采购入库单结算"
                label-for="switch0"
                label-size="sm"
                class="mb-1"
            >
              <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  id="switch0"
                  v-model="feetransportsetting.switch0"
                  value="1"
                  unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨单结算"
                label-for="switch1"
                label-size="sm"
                class="mb-1"
            >
              <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  id="switch1"
                  v-model="feetransportsetting.switch1"
                  value="1"
                  unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="线下销售单结算"
                label-for="switch3"
                label-size="sm"
                class="mb-1"
            >
              <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  id="switch3"
                  v-model="feetransportsetting.switch2"
                  value="1"
                  unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="remark"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="remark"
                  size="sm"
                  v-model="feetransportsetting.remark"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件上传"
                label-for="attachments"
                label-size="sm"
                :class="'mb-1'"
            >
              <attachment-upload v-if="feetransportsetting.loaded" :theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="feetransportsetting.attachments"
                                 :object_type="'feetransportsetting'"
                                 :object_id="feetransportsetting.load_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- 车辆信息 -->
      <b-card header="车辆信息">
        <b-table responsive="sm" :items="initTable" :fields="tableColumns">
          <template #cell(rule0)>
            <b-form-input type="number" v-model="feetransportsetting.rule0"/>
          </template>
          <template #cell(rule1)>
            <b-form-input type="number" v-model="feetransportsetting.rule1"/>
          </template>
          <template #cell(rule2)>
            <b-form-input type="number" v-model="feetransportsetting.rule2"/>
          </template>
          <template #cell(rule3)>
            <b-form-input type="number" v-model="feetransportsetting.rule3"/>
          </template>
          <template #cell(rule4)>
            <b-form-input type="number" v-model="feetransportsetting.rule4"/>
          </template>
          <template #cell(rule5)>
            <b-form-input type="number" v-model="feetransportsetting.rule5"/>
          </template>
          <template #cell(rule6)>
            <b-form-input type="number" v-model="feetransportsetting.rule6"/>
          </template>
          <template #cell(rule7)>
            <b-form-input type="number" v-model="feetransportsetting.rule7"/>
          </template>
          <template #cell(rule8)>
            <b-form-input type="number" v-model="feetransportsetting.rule8"/>
          </template>
          <template #cell(rule9)>
            <b-form-input type="number" v-model="feetransportsetting.rule9"/>
          </template>
          <template #cell(rule10)>
            <b-form-input type="number" v-model="feetransportsetting.rule10"/>
          </template>
          <template #cell(rule11)>
            <b-form-input type="number" v-model="feetransportsetting.rule11"/>
          </template>
          <template #cell(rule12)>
            <b-form-input type="number" v-model="feetransportsetting.rule12"/>
          </template>
        </b-table>
      </b-card>
      <!-- 保存 -->
      <b-col cols="12" class="mt-50">
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
        >
          保存
        </b-button>
        <b-button
            variant="outline-secondary"
            @click="cancel"
        >
          取消
        </b-button>
      </b-col>
    </b-form>
    <!--/ form -->
    <b-modal
        id="modal-select-category"
        no-close-on-backdrop
        ok-title="确认"
        cancel-title="取消"
        centered
        @ok="onSelectCategory"
        size="lg"
        title="分类选择"
    >
      <category-select
          :categoryOptions="category_options"
          :level="level"
          ref="categorySel">
      </category-select>

    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import feetransportsettingStore from './feetransportsettingStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import categorySelect from "@/views/apps/category/category-modal/CategorySelect";
import categoryStore from "@/views/apps/category/categoryStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    ModalSelect,
    AttachmentUpload,
    categorySelect,
  },
  data() {
    return {
      id: ref(0),
      feetransportsetting: ref({}),
      tableColumns: [
        {key: 'rule0', label: '依维柯'},
        {key: 'rule1', label: '三轮车'},
        {key: 'rule2', label: '面包车'},
        {key: 'rule3', label: '4.2米'},
        {key: 'rule4', label: '5.2米'},
        {key: 'rule5', label: '6.8米'},
        {key: 'rule6', label: '7.6米'},
        {key: 'rule10', label: '8.7米'},
        {key: 'rule7', label: '9.6米'},
        {key: 'rule8', label: '13米'},
        {key: 'rule9', label: '17.5米'},
        {key: 'rule11', label: '按件'},
        {key: 'rule12', label: '其他'},
      ],
      initTable: [{}],
      showFrom : true,
      showTo: true,
      notLimitFrom: false,
      notLimitTo: false,
      category_name_value: "",
      category_options: [],
      level:2,
      commonFlag:false,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('feetransportsetting')) store.registerModule('feetransportsetting', feetransportsettingStore)
    if (!store.hasModule('category')) store.registerModule('category', categoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('feetransportsetting')) store.unregisterModule('feetransportsetting')
      if (store.hasModule('category')) store.unregisterModule('category')
    })

    const edit = function () {
      store.dispatch('feetransportsetting/edit', {id: this.id}).then(res => {
        this.feetransportsetting = res.data.data
        if (this.feetransportsetting.new){
          this.feetransportsetting.switch0 = "1"
          this.feetransportsetting.switch1 = "1"
          this.feetransportsetting.switch2 = "1"
        }
        if (this.feetransportsetting.warehouse_id_from == 0) {
          this.notLimitFrom = true
          this.showFrom = false
          this.feetransportsetting.warehouse_name_from = null
        }
        if (this.feetransportsetting.warehouse_id_to == 0) {
          this.notLimitTo = true
          this.showTo = false
          this.feetransportsetting.warehouse_name_to = null
        }
        //是否是通用计费
        if(this.feetransportsetting.new == false && this.feetransportsetting.category_three_id == null){
          this.commonFlag = true
        }
        //编辑
        let ids = [];
        ids.push(this.feetransportsetting.category_id ? this.feetransportsetting.category_id : 0, this.feetransportsetting.category_tow_id ? this.feetransportsetting.category_tow_id : 0, this.feetransportsetting.category_three_id ? this.feetransportsetting.category_three_id : 0);
        if (ids[0]) {
          let content = "";
          let reqParam = ids.join(",")
          store.dispatch('category/searchByIds', {category_ids: reqParam}).then(res => {
            let a = res.data.data;
            for (let i = 0; i < a.length; i++) {
              content += a[i].name;
              content += "/";
              this.category_options.push({"label": a[i].name, "category_id": a[i].category_id});
            }
            this.category_name_value = content;
          });
        }
      })
    }

    const view = function () {
      store.dispatch('feetransportsetting/view', {id: this.id}).then(res => {
        this.feetransportsetting = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.notLimitFrom) {
        //如果选了 不限调出仓库
        this.feetransportsetting.warehouse_name_from = '不限'
        this.feetransportsetting.warehouse_id_from = '0'
      }
      if (this.notLimitTo) {
        //如果选了 不限调出仓库
        this.feetransportsetting.warehouse_name_to = '不限'
        this.feetransportsetting.warehouse_id_to = '0'
      }
      if (this.feetransportsetting.warehouse_name_from == null) {
        toast.error("请选择调出仓库")
        return false
      }
      if (this.feetransportsetting.warehouse_name_to == null) {
        toast.error("请选择调入仓库")
        return false
      }
      if (this.feetransportsetting.company_name == null) {
        toast.error("请选择承运商")
        return false
      }
      if (this.feetransportsetting.default_rule == null) {
        toast.error("请输入默认计价")
        return false
      }
      if (this.feetransportsetting.rule0 < 0 || this.feetransportsetting.rule1 < 0 || this.feetransportsetting.rule2 < 0 || this.feetransportsetting.rule3 < 0 || this.feetransportsetting.rule4 < 0 || this.feetransportsetting.rule5 < 0 || this.feetransportsetting.rule6 < 0 || this.feetransportsetting.rule7 < 0 || this.feetransportsetting.rule8 < 0 || this.feetransportsetting.rule9 < 0 || this.feetransportsetting.rule10 < 0 || this.feetransportsetting.rule11 < 0 || this.feetransportsetting.rule12 < 0){
        toast.error('价格不能是负数')
        return false
      }
      if(!isEmpty(this.feetransportsetting.category_three_id) && this.commonFlag){
        toast.error('不能覆盖通用计费')
        return false
      }
      // if (isEmpty(this.feetransportsetting.rule0) && isEmpty(this.feetransportsetting.rule1) && isEmpty(this.feetransportsetting.rule2) && isEmpty(this.feetransportsetting.rule3) && isEmpty(this.feetransportsetting.rule4) && isEmpty(this.feetransportsetting.rule5) && isEmpty(this.feetransportsetting.rule6) && isEmpty(this.feetransportsetting.rule7) && isEmpty(this.feetransportsetting.rule8) && isEmpty(this.feetransportsetting.rule9) && isEmpty(this.feetransportsetting.rule10) && isEmpty(this.feetransportsetting.rule11) && isEmpty(this.feetransportsetting.rule12)){
      //   toast.error('价格不能全为空')
      //   return false
      // }
      store.dispatch('feetransportsetting/save', this.feetransportsetting).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-feetransportsetting-list'});
        } else {
          toast.error(res.data.data)
        }

      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.feetransportsetting[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const onUploaded = function (id, attachment, result) {
      this.feetransportsetting[id] = result
    }

    const limitFrom = function () {
      this.showFrom = !this.showFrom
    }

    const limitTo = function () {
      this.showTo = !this.showTo
    }

    const onSelectCategory = function () { //赋值回显
      this.feetransportsetting.category_id = isEmpty(this.$refs.categorySel.categoryId) ? null : this.$refs.categorySel.categoryId;
      this.feetransportsetting.category_tow_id = isEmpty(this.$refs.categorySel.categoryTowId) ? null : this.$refs.categorySel.categoryTowId;
      this.feetransportsetting.category_three_id = isEmpty(this.$refs.categorySel.categoryThreeId) ? null : this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      let new_content = "";
      if(!isEmpty(this.$refs.categorySel.categoryTowName)){
        new_content += this.$refs.categorySel.categoryName + "/";
        new_content += this.$refs.categorySel.categoryTowName + "/";
        new_content += this.$refs.categorySel.categoryThreeName + "/";
      }
      this.category_name_value = new_content;
    }

    return {
      edit,
      view,
      cancel,
      save,
      onSelectCategory,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      onUploaded,
      limitFrom,
      limitTo,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
